import React, { Component } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import Button from "../Button"

class FlashCardButton extends Component {
  componentDidMount() {
    this.props.onReset()
  }

  newChallenge = () => {
    this.props.onReset()
    navigate(this.props.nextQuestionSlug)
  }

  onClick = () => {
    this.props.hasBeenRevealed ? this.newChallenge() : this.props.onFlip()
  }

  buttonText = () => {
    return this.props.hasBeenRevealed ? "GO!" : "Click to reveal"
  }

  render() {
    return (
      <Button onClick={this.onClick} size="l">
        {this.buttonText()}
      </Button>
    )
  }
}

FlashCardButton.defaultProps = {
  hasBeenRevealed: false,
  nextQuestionSlug: null,
  onFlip: () => {},
  onReset: () => {},
}

FlashCardButton.propTypes = {
  hasBeenRevealed: PropTypes.bool,
  nextQuestionSlug: PropTypes.string,
  onFlip: PropTypes.func,
  onReset: PropTypes.func,
}

export default FlashCardButton
