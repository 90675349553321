import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { connect } from "react-redux"

import { setCardRevealed, resetCardRevealed } from "../actions/challenge"

import { Link } from "gatsby"
import Page from "../components/Page"
import Centerer from "../components/Centerer"
import FlashCard from "../components/FlashCard"
import FlashCardButton from "../components/FlashCardButton"

const Challenge = ({
  data,
  isCardRevealed,
  hasCardBeenRevealed,
  nextQuestionSlug,
  setCardRevealedAction,
  resetCardRevealedAction,
}) => {
  const pageData = data.sitePage.context

  return (
    <Page>
      <Centerer>
        <h1>Lesson: {pageData.lessonTitle}</h1>
        <Link to={pageData.lessonSlug}>Back</Link>
        <FlashCard
          challenge={pageData.challenge}
          isRevealed={isCardRevealed}
          onFlip={() => {
            setCardRevealedAction(!isCardRevealed)
          }}
        />

        <FlashCardButton
          hasBeenRevealed={hasCardBeenRevealed}
          onFlip={() => {
            setCardRevealedAction(!isCardRevealed)
          }}
          onReset={resetCardRevealedAction}
          nextQuestionSlug={nextQuestionSlug}
        />
      </Centerer>
    </Page>
  )
}

Challenge.defaultProps = {
  isCardRevealed: false,
  hasCardBeenRevealed: false,
  nextQuestionSlug: null,
  setCardRevealedAction: () => {},
  resetCardRevealedAction: () => {},
}

Challenge.propTypes = {
  isCardRevealed: PropTypes.bool,
  hasCardBeenRevealed: PropTypes.bool,
  nextQuestionSlug: PropTypes.string,
  setCardRevealedAction: PropTypes.func,
  resetCardRevealedAction: PropTypes.func,
}

const mapStateToProps = (state, props) => {
  const { questions, isCardRevealed, hasCardBeenRevealed } = state.challenge
  const pageData = props.data.sitePage.context
  const currentQuestion = pageData.challenge.question
  const currentQuestionIndex = questions.indexOf(currentQuestion)
  const nextQuestionSlug =
    currentQuestionIndex + 1 >= questions.length
      ? pageData.lessonSlug
      : `${pageData.lessonSlug}${questions[currentQuestionIndex + 1]}`

  return {
    isCardRevealed,
    hasCardBeenRevealed,
    nextQuestionSlug,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCardRevealedAction: isRevealed => dispatch(setCardRevealed(isRevealed)),
    resetCardRevealedAction: () => dispatch(resetCardRevealed()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Challenge)

export const query = graphql`
  query($slug: String!) {
    sitePage(context: { slug: { eq: $slug } }) {
      context {
        lessonTitle
        lessonSlug
        challenge {
          question
          answer
          translation
        }
      }
    }
  }
`
