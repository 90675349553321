import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import styles from "./styles.module.scss"

const Card = ({ children, isFlipped, onClick }) => {
  return (
    <div
      className={classnames(styles.card, {
        [styles.isFlipped]: isFlipped,
        [styles.canClick]: onClick,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

Card.defaultProps = {
  isFlipped: false,
  onClick: null,
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  isFlipped: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Card
