import shuffleArray from "../utils/shuffleArray"

export const setCardRevealed = isCardRevealed => {
  return {
    type: "SET_CARD_REVEALED",
    payload: {
      isCardRevealed,
    },
  }
}

export const resetCardRevealed = () => {
  return {
    type: "RESET_CARD_REVEALED",
  }
}

export const getQuestions = lessonChallenges => {
  return {
    type: "GET_QUESTIONS",
    payload: {
      questions: shuffleArray(lessonChallenges).map(
        challenge => challenge.question
      ),
    },
  }
}
