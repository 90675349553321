import React, { Component } from "react"
import PropTypes from "prop-types"
import styles from "./styles.module.scss"

import Card from "../Card"

class FlashCard extends Component {
  challengeText = () => {
    return this.props.challenge[this.props.isRevealed ? "answer" : "question"]
  }

  render() {
    const { challenge, isRevealed, onFlip } = this.props

    if (!challenge) {
      return null
    }

    return (
      <div className={styles.cardSpacer}>
        <Card isFlipped={isRevealed} onClick={onFlip}>
          {isRevealed && (
            <p className={styles.revealedQuestionText}>{challenge.question}</p>
          )}

          <p className={styles.challengeText}>{this.challengeText()}</p>

          {isRevealed && challenge.translation && (
            <p className={styles.translationText}>
              Translation: {challenge.translation}
            </p>
          )}
        </Card>
      </div>
    )
  }
}

FlashCard.defaultProps = {
  challenge: {},
  isRevealed: false,
  onFlip: () => {},
}

FlashCard.propTypes = {
  challenge: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    translation: PropTypes.string,
  }).isRequired,
  isRevealed: PropTypes.bool,
  onFlip: PropTypes.func,
}

export default FlashCard
